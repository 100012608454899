@tailwind base;
@tailwind components;
@tailwind utilities;

.item-container{
	@apply flex flex-col justify-between w-full space-y-6 text-2xl text-white
	capitalize md:flex-row md:space-y-0 md:space-x-8;
}
.section{
	@apply mb-2 sm:mb-4 md:mb-5;
}
.container{
	@apply max-w-screen-xl mx-auto px-7;
}
.title{
  @apply leading-tight text-primary-dark mb-4 md:mb-7 text-2xl md:text-3xl xl:text-4xl md:font-bold;
}
#hero {
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
	background-position: center;
  background-size: cover;
}

/* Checkbox */
.custom-checkbox {
  @apply w-6 h-6 inline-block relative;
}
.custom-checkbox input[type='checkbox'] {
  @apply h-0 w-0 opacity-0;
}
.custom-checkbox .circle {
  @apply absolute top-0 left-0 w-6 h-6 border-2 border-primary100 rounded-full cursor-pointer;  
}
.custom-checkbox .checkmark {
  @apply text-primary100 absolute top-1/2 left-1/2 hidden;
  transform: translate(-50%, -50%);
}

.custom-checkbox input[type='checkbox']:checked + .circle .checkmark {
  @apply block; 
}