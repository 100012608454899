.item {
  @apply relative w-full cursor-pointer overflow-hidden;
}
#item-gradient {
  @apply absolute top-0 left-0 right-0 bottom-0 w-full h-full bg-primary-dark opacity-60 group-hover:transition-colors group-hover:duration-[4s] group-hover:opacity-80;
}
.item-gradient:hover {
  background: linear-gradient(
    90deg,
    rgba(3, 20, 41, 0.75) 0,
    rgba(3, 20, 41, 0.25) 50%,
    rgba(3, 20, 41, 0.25)
  );
}

#erq9t3lfd8r21 {
  animation-name: erq9t3lfd8r21_s_do, erq9t3lfd8r21_s_da;
  animation-duration: 4000ms;
  animation-fill-mode: forwards;
  animation-timing-function: linear;
  animation-direction: normal;
  animation-iteration-count: infinite;
}

@keyframes erq9t3lfd8r21_s_do {
  0% {
    stroke-dashoffset: 2426.95;
  }

  62.500000% {
    stroke-dashoffset: -400;
  }

  100% {
    stroke-dashoffset: -400;
  }
}

@keyframes erq9t3lfd8r21_s_da {
  0% {
    stroke-dasharray: 526.95, 2300;
  }

  62.500000% {
    stroke-dasharray: 526.95, 2300;
  }

  100% {
    stroke-dasharray: 526.95, 2300;
  }
}

.issues-hero-svg {
  @apply absolute top-[70%] right-[-200px] h-[30rem] z-20;
  transform: translateY(-60%);
}
