.item {
  @apply relative w-full cursor-pointer overflow-hidden;
}

.item-gradient {
  @apply absolute top-0 left-0 right-0 bottom-0 w-full h-full bg-primary-dark opacity-60 group-hover:transition-colors group-hover:duration-[4s] group-hover:opacity-80;
}

.item-gradient:hover {
  background: linear-gradient(90deg,
    rgba(3, 20, 41, 0.75) 0,
    rgba(3, 20, 41, 0.25) 50%,
    rgba(3, 20, 41, 0.25)
  );
}
.container{
  @apply max-w-screen-xl mx-auto px-[30px] overflow-hidden;
}
.about-it{
  background: linear-gradient(180deg, #f2f5fa 0, #fff);
}
.about-img{
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, .1), 0 4px 6px -2px rgba(0, 0, 0, .05);
}

.slider-container {
  display: flex;
  transition: transform 0.5s ease;
}

.slider-item {
  flex-shrink: 0;
  min-width: 100%;
  max-width: 100%;
}
.slider-hidden{
  opacity: 0;
  transform: translateX(100%);
  transition: opacity 0.5s ease, transform 0.5s ease;
}
.slider-item.active {
  opacity: 1;
  transform: translateX(0);
}