.item {
  @apply relative w-full cursor-pointer overflow-hidden;
}
.item-gradient {
  @apply absolute top-0 left-0 right-0 bottom-0 w-full h-full bg-primary-dark opacity-60 group-hover:transition-colors group-hover:duration-[4s] group-hover:opacity-80;
}
.item-gradient:hover {
  background: linear-gradient(
    90deg,
    rgba(3, 20, 41, 0.75) 0,
    rgba(3, 20, 41, 0.25) 50%,
    rgba(3, 20, 41, 0.25)
  );
}

.stories-section {
  background: linear-gradient(180deg,#f2f5fa 0,#fff);
}